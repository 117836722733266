const cachePrefix = () => {
    return `@@${process.env.VUE_APP_TENANT_SLUG}@@::`;
}

export function get(key) {
    const res = localStorage.getItem(`${cachePrefix()}${key}`);
    return JSON.parse(res);
}

export function set(key, val) {
    const strVal = JSON.stringify(val);
    localStorage.setItem(`${cachePrefix()}${key}`, strVal);
}

export function del(key) {
    localStorage.removeItem(`${cachePrefix()}${key}`);
}