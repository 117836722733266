<script setup>
import {ArrowRightIcon} from "@radix-icons/vue";
</script>

<template>
  <v-container class="no-padding-margin">
    <v-row class="no-padding-margin">
      <v-col class="no-padding-margin search-bar">
        <v-text-field
            @blur="emitSearch"
            @keyup.enter="emitSearch"
            v-model="search"
            :label="$t('nav.search')"
            variant="underlined"
        />
      </v-col>
    </v-row>
    <v-row class="no-padding-margin">
      <v-col class="no-padding-margin">
        <div><span class="fine-text secondary-text-opacity">{{ $t('nav.quickLinks') }}</span></div>
        <div>
          <span class="left-spacer--s link">
            <ArrowRightIcon class="icon--s secondary-text-opacity"/> {{ $t('nav.landingPage') }}
          </span>
        </div>
        <div>
          <span class="left-spacer--s link">
            <ArrowRightIcon class="icon--s secondary-text-opacity"/> {{ $t('nav.courseLibrary') }}
          </span>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "SearchComponent",
  data() {
    return {
      search: "",
    }
  },
  methods: {
    emitSearch() {
      this.$emit("search", this.search);
    }
  },
};
</script>