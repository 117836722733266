import axios from "axios";
import router from "@/router";
import routerConfig from "../router/config.json";
import { auth0 } from "@/modules/auth0";

// Checks where to connect
function isLocalIpAddress(hostname) {
    return hostname === 'localhost' ||
        hostname.startsWith('127.') || // Loopback
        /^192\.168\./.test(hostname) || // Private network IP
        /^10\./.test(hostname) ||
        /^172\.(1[6-9]|2[0-9]|3[0-1])\./.test(hostname);
}

// Determines API url
const baseURL = isLocalIpAddress(window.location.hostname)
    ? process.env.VUE_APP_API_ADDRESS_DEV
    : process.env.VUE_APP_API_ADDRESS_PROD;

/**
 * Constructs the Authorization header using the token stored in cookies, if present.
 * @returns {Object} - An object containing the Authorization header, or an empty object if no token is found.
 */
async function setAuthorizationHeader() {
    const headers = {};

    try {
        headers['Authorization'] = `Bearer ${await auth0.getAccessTokenSilently()}`;
    } catch (err) {
        if (isLocalIpAddress(window.location.hostname)) console.error("Error fetching authorization token:", err);
    }

    return headers;
}

/**
 * Asynchronously sends a POST request to the server with provided data.
 * @param {string} url - The endpoint URL to which the post request is made. This is appended to the baseURL.
 * @param {Object} obj - The data object to be sent with the post request.
 * @param {Function} _callback - A callback function to handle the response. First parameter of type {success: Boolean, res: Object}.
 * @param {boolean} [redirect=true] - A flag indicating whether to redirect on error. Defaults to true.
 *
 * This function constructs headers with authorization, sends the request using axios with credentials, and handles both success and failure cases.
 * On success, the callback is called with a success flag and the response data.
 * On error, it logs the error if the hostname is a local IP address and calls the callback with the result of the errorHandler function,
 * potentially handling redirects based on the redirect flag.
 *
 * @returns {void}
 */
export async function postToServer(url, obj, _callback, redirect = true) {
    const headers = await setAuthorizationHeader();

    await axios({
        headers: headers,
        withCredentials: true,
        url: `${baseURL}${url}`, // Server Addresses can be changed globally in .env file
        method: "post",
        data: obj,
    })
        .then((res) => {
            _callback({
                success: true,
                res: res.data
            });
        })
        .catch((err) => {
            if (isLocalIpAddress(window.location.hostname)) console.log(err);
            _callback(errorHandler(err, redirect));
        });
}

/**
 * Asynchronously sends a DELETE request to the server with provided data.
 * @param {string} url - The endpoint URL to which the delete request is made. This is appended to the baseURL.
 * @param {Object} obj - The data object to be sent with the delete request.
 * @param {Function} _callback - A callback function to handle the response. First parameter of type {success: Boolean, res: Object}.
 * @param {boolean} [redirect=true] - A flag indicating whether to redirect on error. Defaults to true.
 *
 * This function constructs headers with authorization, sends the request using axios with credentials, and handles both success and failure cases.
 * On success, the callback is called with a success flag and the response data.
 * On error, it logs the error if the hostname is a local IP address and calls the callback with the result of the errorHandler function,
 * potentially handling redirects based on the redirect flag.
 *
 * @returns {void}
 */
export async function deleteToServer(url, obj, _callback, redirect = true) {
    const headers = await setAuthorizationHeader();

    await axios({
        headers: headers,
        withCredentials: true,
        url: `${baseURL}${url}`, // Server Addresses can be changed globally in .env file
        method: "delete",
        data: obj,
    })
        .then((res) => {
            _callback({
                success: true,
                res: res.data
            });
        })
        .catch((err) => {
            if (isLocalIpAddress(window.location.hostname)) console.log(err);
            _callback(errorHandler(err, redirect));
        });
}

/**
 * Asynchronously sends a PUT request to the server with provided data.
 * @param {string} url - The endpoint URL to which the put request is made. This is appended to the baseURL.
 * @param {Object} obj - The data object to be sent with the put request.
 * @param {Function} _callback - A callback function to handle the response. First parameter of type {success: Boolean, res: Object}.
 * @param {boolean} [redirect=true] - A flag indicating whether to redirect on error. Defaults to true.
 *
 * This function constructs headers with authorization, sends the request using axios with credentials, and handles both success and failure cases.
 * On success, the callback is called with a success flag and the response data.
 * On error, it logs the error if the hostname is a local IP address and calls the callback with the result of the errorHandler function,
 * potentially handling redirects based on the redirect flag.
 *
 * @returns {void}
 */
export async function putToServer(url, obj, _callback, redirect = true) {
    const headers = await setAuthorizationHeader();

    await axios({
        headers: headers,
        withCredentials: true,
        url: `${baseURL}${url}`, // Server Addresses can be changed globally in .env file
        method: "put",
        data: obj,
    })
        .then((res) => {
            _callback({
                success: true,
                res: res.data
            });
        })
        .catch((err) => {
            if (isLocalIpAddress(window.location.hostname)) console.log(err);
            _callback(errorHandler(err, redirect));
        });
}

/**
 * Asynchronously sends a GET request to the server.
 * @param {string} url - The endpoint URL to which the get request is made. This is appended to the baseURL.
 * @param {Function} _callback - A callback function to handle the response. First parameter of type {success: Boolean, res: Object}.
 * @param {boolean} [redirect=true] - A flag indicating whether to redirect on error. Defaults to true.
 *
 * This function constructs headers with authorization and sends the request using axios with credentials. It handles both success and failure cases:
 * - On success, the callback is called with a success flag and the response data.
 * - On error, it logs the error if the hostname is a local IP address and calls the callback with the result of the errorHandler function, potentially handling redirects based on the redirect flag.
 *
 * @returns {void}
 */
export async function getFromServer(url, _callback, redirect = true) {
    const headers = await setAuthorizationHeader();

    await axios({
        headers: headers,
        withCredentials: true,
        url: `${baseURL}${url}`,
        method: "get",
    })
        .then((res) => {
            _callback({
                success: true,
                res: res.data
            });
        })
        .catch((err) => {
            if (isLocalIpAddress(window.location.hostname)) console.log(err);
            _callback(errorHandler(err, redirect));
        });
}

/**
 * Handles errors from API requests.
 * @param {Object} err - The error object from an Axios request.
 * @param {Boolean} redirect - Redirect functionality for 403 handler.
 */
function errorHandler(err, redirect) {
    if (err.response) {
        if (err.response.status === 403) handle403(redirect);
        if (err.response.data instanceof Array || err.response.data instanceof Object) return {
            success: false,
            res: [].concat(err.response.data)
        }
    } else return {
        success: false,
        res: [
            {
                code: 500,
                message: err.message
            }
        ]
    }
}

/**
 * Handles 403 errors, redirecting user to authorization page.
 * @param {Boolean} redirect - Redirect functionality.
 */
export function handle403(redirect) {
    if (redirect) router.push(routerConfig.error);
}