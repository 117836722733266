<script setup>
import {MagnifyingGlassIcon} from "@radix-icons/vue";
</script>

<template>
  <div class="nav-content mobile-top">
    <div class="item">
      <UserNavigationComponent class="item"/>
    </div>
    <div class="search-item top">
      <MagnifyingGlassIcon @click="expandSearch = !expandSearch" class=" icon"/>
    </div>
    <div class="search-item bottom search-container">
      <SearchComponent @search="emitSearch"/>
    </div>
  </div>
</template>
<script>
import UserNavigationComponent from "./UserNavigationComponent.vue";
import SearchComponent from "@/components/data/SearchComponent.vue";
export default {
  components: {
    UserNavigationComponent,
    SearchComponent
  },
  data(){
    return{
      expandSearch: false
    }
  },
  methods: {
    goToRoute(routeName) {
      this.$router.push({ name: routeName });
    },
    emitSearch(e = "") {
      this.$emit("search", e);
    }
  },
  watch: {
    expandSearch() {
      if (this.expandSearch) {
        document.querySelector('.nav-content.mobile-top .search-container')?.classList.add('expand-search');
      } else {
        document.querySelector('.nav-content.mobile-top .search-container')?.classList.remove('expand-search');
      }
    }
  }
};
</script>
<style lang="scss">
.nav-content.mobile-top > .item{
  padding:calc(.65rem + 1.25vw + .65vh);
}
.nav-content.mobile-top > .search-item.top{
  padding-left:calc(.65rem + 1.25vw + .65vh);
  padding-right:calc(.65rem + 1.25vw + .65vh);
  cursor: pointer;
}
.nav-content.mobile-top > .search-item.bottom{
  margin-bottom:calc(.65rem + 1.25vw + .65vh);
  margin-left:calc(.65rem + 1.25vw + .65vh);
  margin-right:calc(.65rem + 1.25vw + .65vh);
}
.nav-content.mobile-top .search-container.expand-search {
  height: auto;
  max-height: 20vh;
}
.nav-content.mobile-top .search-container {
  transition: max-height .3s ease-out;
  overflow: hidden;
  max-height: 0;
}
</style>
