<template>
  <div class="center">
    <h3>{{ $t('legalDisclosure') }}</h3>
    <div class="spacer--s"/>
    <span>{{ legalDisclosure.legalName }} · {{ legalDisclosure.address }} · {{ legalDisclosure.contact }}</span>
  </div>
</template>
<script>
import legalDisclosure from "@/tenant/legalDisclosure.json";
export default{
  data(){
    return{
      legalDisclosure
    }
  }
}
</script>
<style scoped lang="scss">

</style>