import {postToServer,putToServer} from "@/utils/fetcher";
import {getLocale} from "@/lang/langSetup";

/**
 * Subscribes an email to the newsletter and sets its locale.
 * @param {string} email - The email address to register for the newsletter.
 * @param {string} [locale=getLocale()] - The locale of the subscriber. Defaults to the result of getLocale().
 * @returns {Promise<Result>} - The result of the subscription attempt. Success indicates the email was registered, while failure returns an error array.
 * @throws {Error} - Encountered during the registration process.
 */
export async function registerNewsletter(email, locale = getLocale()) {
    let result;

    await postToServer("/api/newsletter", {
        email,
        locale,
    }, (res) => {
        result = res;
    }, false);

    return result;
}

/**
 * Verifies the newsletter subscription with a given token.
 * @param {string} verificationToken - The token for verifying the newsletter subscription.
 * @returns {Promise<Result>} - The outcome of the verification process. Success indicates the subscription was verified, while failure returns an error array.
 * @throws {Error} - Encountered during the verification process.
 */
export async function verifyNewsletter(verificationToken) {
    let result;

    await putToServer("/api/newsletter", {
        verificationToken
    }, (res) => {
        result = res;
    }, false);

    return result;
}