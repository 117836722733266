<script setup>
import {InstagramLogoIcon} from "@radix-icons/vue";
import { useAuth0 } from "@auth0/auth0-vue";
const { loginWithRedirect } = useAuth0();

async function login() {
  await loginWithRedirect();
}
</script>

<template>
  <div class="main-div-vertical encouraging-academy">
    <h1 class="hide-seo-friendly">
      Encouraging Academy Ermutigungs Akademie Mental Health Mentale Gesundheit Digitale Kurse Digital Courses
      Personality Persönlichkeitsentwicklung
    </h1>
    <div class="page-navigation">
      <div class="page-navigation-content">
        <div class="most-right">
          <v-container class="no-padding-margin">
            <v-row>
              <v-col>
                <InstagramLogoIcon class="icon--xl"/>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
    </div>
    <div class="page-section text-center">
      <!-- Background Graphics -->
      <div class="background-container">
        <div class="background"/>
      </div>
      <div class="page-section-background page-section-min-padding">
        <div class="center">
          <div>
            <Icon
                color="var(--s-primary)"
                size="calc(5.25rem + 2.35vw + 1.35vh)"
            />
          </div>
          <div class="spacer"/>
          <div>
            <h1>{{ tenantName }}</h1>
          </div>
          <div>
            <h5 v-html="$t('slogan') "/>
          </div>
          <div class="spacer"/>
          <v-container class="no-padding-margin">
            <v-row>
              <v-col>
                <v-btn
                    color="var(--s-primary)"
                    class="input-button dark-color form--s"
                    @click="login"
                >
                  {{ $t('toSignin') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
    </div>
    <div class="page-section-adjustable">
      <div class="page-section-item text-center">
        <v-container class="no-padding-margin">
          <v-row>
            <v-col>
              <h2 v-html="$t('landing.section1.title')"/>
              <span v-html="$t('landing.section1.content1')"/><br/>
              <span v-html="$t('landing.section1.content2')"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-img
                  class="center-horizontal"
                  width="calc(8rem + 35vmin)"
                  src="/img/tenant/encouraging_academy_present.webp"
              />
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
    <div class="page-section-adjustable">
      <div class="page-section-item text-center">
        <v-img class="icon--giant center-horizontal" src="/img/tenant/encouraging_academy_crown.webp"/>
        <div class="spacer--s"/>
        <h2>{{ $t('landing.features.title') }}</h2>
        <div class="spacer"/>
        <v-container class="no-padding-margin">
          <v-row>
            <v-col class="feature-item">
              <v-img class=" icon--giant center-horizontal"
                     src="/img/tenant/encouraging_academy_iconmeetings.webp"/>
              <div class="spacer"/>
              <h4 v-html="$t('landing.features.meetings')"/>
            </v-col>
            <v-col class="feature-item">
              <v-img class=" icon--giant center-horizontal"
                     src="/img/tenant/encouraging_academy_iconrecommendations.webp"/>
              <div class="spacer"/>
              <h4 v-html="$t('landing.features.recommendations')"/>
            </v-col>
            <v-col class="feature-item">
              <v-img class=" icon--giant center-horizontal"
                     src="/img/tenant/encouraging_academy_iconinteractive.webp"/>
              <div class="spacer"/>
              <h4 v-html="$t('landing.features.interactive')"/>
            </v-col>
            <v-col class="feature-item">
              <v-img class=" icon--giant center-horizontal"
                     src="/img/tenant/encouraging_academy_iconcommunity.webp"/>
              <div class="spacer"/>
              <h4 v-html="$t('landing.features.community')"/>
            </v-col>
            <v-col class="feature-item">
              <v-img class=" icon--giant center-horizontal"
                     src="/img/tenant/encouraging_academy_icontechnology.webp"/>
              <div class="spacer"/>
              <h4 v-html="$t('landing.features.technology')"/>
            </v-col>
            <v-col class="feature-item">
              <v-img class=" icon--giant center-horizontal"
                     src="/img/tenant/encouraging_academy_iconresponsive.webp"/>
              <div class="spacer"/>
              <h4 v-html="$t('landing.features.responsive')"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <NewsletterRegistrationComponent/>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
    <div class="page-section-adjustable">
      <div class="page-section-item text-left">
        <v-container>
          <v-row>
            <v-col>
              <v-img class=" icon--giant" src="/img/tenant/encouraging_academy_scribbles1.webp"/>
              <h2 v-html="$t('landing.section2.title')"/>
              <span v-html="$t('landing.section2.content1')"/><br/>
              <span v-html="$t('landing.section2.content2')"/>
              <div class="spacer"/>
              <div class="opacity--accent divider"/>
              <h5 v-html="$t('landing.section2.content3')"/>
            </v-col>
            <v-col :cols="this.$vssWidth < 1180 ? '4' : '6'">
              <v-img
                  class="center-horizontal"
                  width="25vmin"
                  src="/img/tenant/encouraging_academy_showingmap.webp"
              />
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
    <div class="page-section-adjustable">
      <div class="page-section-item text-right">
        <v-container>
          <v-row>
            <v-col :cols="this.$vssWidth < 1180 ? '4' : '6'">
              <v-img
                  class="center-horizontal"
                  width="calc(8rem + 25vmin)"
                  src="/img/tenant/encouraging_academy_happycomeback.webp"
              />
            </v-col>
            <v-col>
              <v-img class=" icon--giant position--right" src="/img/tenant/encouraging_academy_scribbles4.webp"/>
              <h2 v-html="$t('landing.section3.title')"/>
              <span v-html="$t('landing.section3.content1')"/><br/>
              <span v-html="$t('landing.section3.content2')"/>
              <div class="spacer"/>
              <div class="opacity--accent divider"/>
              <h5 v-html="$t('landing.section3.content3')"/>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
    <div class="page-section-adjustable text-center">
      <v-img class=" icon--giant center-horizontal" src="/img/tenant/encouraging_academy_glasses.webp"/>
      <div class="spacer--s"/>
      <h2>{{ $t('landing.section4.title') }}</h2>
      <h5 v-html="$t('landing.section4.hint')"/>
      <div class="spacer"/>
      <v-container class="no-padding-margin">
        <v-row>
          <v-col>
            <v-img aspect-ratio="1/1" src="/img/tenant/encouraging_academy_sylviastark.webp"/>
          </v-col>
          <v-col>
            <v-container class="no-padding-margin">
              <v-row>
                <v-col>
                  <v-img aspect-ratio="1/1" src="/img/tenant/encouraging_academy_person1.webp"/>
                </v-col>
                <v-col>
                  <v-img aspect-ratio="1/1" src="/img/tenant/encouraging_academy_person2.webp"/>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-img aspect-ratio="1/1" src="/img/tenant/encouraging_academy_person3.webp"/>
                </v-col>
                <v-col>
                  <v-img aspect-ratio="1/1" src="/img/tenant/encouraging_academy_person4.webp"/>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
          <v-col>
            <v-container class="no-padding-margin">
              <v-row>
                <v-col>
                  <v-img aspect-ratio="1/1" src="/img/tenant/encouraging_academy_person5.webp"/>
                </v-col>
                <v-col>
                  <v-img aspect-ratio="1/1" src="/img/tenant/encouraging_academy_person6.webp"/>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-img aspect-ratio="1/1" src="/img/tenant/encouraging_academy_person7.webp"/>
                </v-col>
                <v-col>
                  <v-img aspect-ratio="1/1" src="/img/tenant/encouraging_academy_person8.webp"/>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
      <div class="spacer"/>
      <v-container>
        <v-row>
          <v-col class="text-left lecturer-item center-horizontal">
            <v-img class="icon--giant" src="/img/tenant/encouraging_academy_scribbles3.webp"/>
            <div class="spacer--s"/>
            <h5 v-html="$t(`landing.section4.${model.selectedLecturer}.title`)"/>
            <h2 v-html="$t(`landing.section4.${model.selectedLecturer}.name`)"/>
            <span v-html="$t(`landing.section4.${model.selectedLecturer}.content`)"/><br/>
            <div class="spacer--s"/>
            <a :href="$t(`landing.section4.${model.selectedLecturer}.url`)" target="_blank">{{ $t('landing.section4.toPortfolio') }}</a>
            <div class="spacer"/>
            <div class="opacity--accent divider"/>
            <h5 v-html="$t(`landing.section4.${model.selectedLecturer}.experience`)"/>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div class="page-section-adjustable">
      <div class="page-section-item text-center">
        <LegalDisclosure/>
      </div>
    </div>
  </div>
</template>
<script>
import Icon from "@/tenant/TenantIcon.vue";
import LegalDisclosure from "@/views/LegalDisclosure.vue";
import NewsletterRegistrationComponent from "@/modules/newsletter/NewsletterRegistrationComponent.vue";

export default {
  components: {
    Icon,
    LegalDisclosure,
    NewsletterRegistrationComponent
  },
  data() {
    return {
      tenantName: process.env.VUE_APP_TENANT_NAME,
      model: {
        selectedLecturer: "sylviastark",
      }
    }
  }
}
</script>
<style scoped lang="scss">
.main-div-vertical.encouraging-academy {
  background-color: var(--background-secondary-light);
}

.encouraging-academy .background-container {
  position: absolute;
  width: 100vw;
  height: 100vh;
  overflow: visible;
}

.encouraging-academy .background {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100vw;
  height: auto;
  min-height: 100%;
  background-image: url('/public/img/tenant/encouraging_academy_landingbackground.webp');
  background-size: cover;
  background-position: left top;
  overflow: visible;
}

.encouraging-academy .feature-item {
  background-color: #faf9f7;
  margin: calc(.5rem + .5vw);
  border-radius: 3px;
  border: solid .5px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 10px 10px 0px 0px rgba(0, 0, 0, 0.4);
  box-shadow: 10px 10px 0px 0px #DFBF6A60;
}

.encouraging-academy .lecturer-item {
  max-width: calc(14rem + 16vw);
}

@media screen and (max-width: 1200px) {
  .encouraging-academy .background-container {
    opacity: .35;
  }
}

</style>