import {createApp} from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import {auth0} from "@/modules/auth0.js";

// I18n
import {createI18n} from 'vue-i18n'
import {getLocale, getMessages} from "@/lang/langSetup";

const messages = getMessages();
const locale = getLocale();
const i18n = createI18n({
    locale: locale,
    fallbackLocale: 'en',
    messages,
})

// Pinia
import {createPinia} from 'pinia';

// Vuetify
import 'vuetify/styles'
import {vuetifyInstance} from "@/style/vuetify.js";

// Vue Screen Size
import VueScreenSize from 'vue-screen-size';

const pinia = createPinia();

createApp(App)
    .use(auth0)
    .use(vuetifyInstance)
    .use(store)
    .use(router)
    .use(i18n)
    .use(pinia)
    .use(VueScreenSize)
    .mount("#app");
