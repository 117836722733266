<script setup>
import {HomeIcon, ArchiveIcon, GearIcon} from "@radix-icons/vue";
</script>

<template>
  <v-container class="nav-content mobile-bottom no-padding">
    <v-row no-gutters justify="space-between" align="center">
      <v-col
          v-if="!inDashboard"
        @click="goToRoute('app-dashboard')"
        no-gutters
        class="btn-navigation link"
      >
        <HomeIcon class="icon--xl"/>
      </v-col>
      <v-col
          v-else
        @click="goToRoute('app-store')"
        no-gutters
        class="btn-navigation link"
      >
        <ArchiveIcon class="icon--xl"/>
      </v-col>
      <v-col
        @click="goToRoute('app-settings')"
        no-gutters
        class="btn-navigation link"
      >
        <GearIcon class="icon--xl"/>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data(){
    return {
      inDashboard: false
    }
  },
  methods: {
    goToRoute(routeName) {
      this.$router.push({ name: routeName });
    },
  },
  watch: {
    '$route': {
      immediate: true,
      handler(to) {
        this.inDashboard = to.name === "app-dashboard";
      }
    },
  }
};
</script>
<style lang="scss">
.nav-content.mobile-bottom{
  background-color: transparent !important;
  pointer-events: none;
}
.nav-container.mobile-bottom{
  position:fixed;
  width:100vw;
  bottom:0;
  left:0;
}
</style>
