import { createAuth0 } from "@auth0/auth0-vue";

const options = {
    domain:           process.env.VUE_APP_AUTH0_DOMAIN,
    clientId:         process.env.VUE_APP_AUTH0_CLIENT_ID,
    useRefreshTokens: true,
    cacheLocation:    'localstorage',
    scope:            'openid profile email offline_access',
    authorizationParams: {
        redirect_uri: `${process.env.VUE_APP_BASE_URL_DEV}${process.env.VUE_APP_AUTH0_CALLBACK_URL}`,
        audience:     process.env.VUE_APP_AUTH0_AUDIENCE,
    },
};

export const auth0 = createAuth0(options);

const auth0RefreshTokenKey = "refresh_token";
const auth0CacheKey = () => {
    return `@@auth0spajs@@::${auth0.clientOptions.clientId}::${auth0.clientOptions.authorizationParams.audience}::${auth0.clientOptions.scope}`
}
const auth0UserKey = () => {
    return `@@auth0spajs@@::${auth0.clientOptions.clientId}::@@user@@`
}

/**
 * Cleans up the Auth0 cache in localStorage while retaining the refresh token.
 * Removes all user data from localStorage.
 *
 * This function triggers refresh token authorization on each session start.
 */
const cleanUpAuth0 = () => {
    const cacheData = localStorage.getItem(auth0CacheKey());

    // Cleans up cache, saves refresh_token
    if (cacheData) {
        let data = JSON.parse(cacheData);

        const retainRefreshToken = (obj) => {
            Object.keys(obj).forEach(key => {
                if (key !== auth0RefreshTokenKey) {
                    if (typeof obj[key] === 'object' && obj[key] !== null) {
                        retainRefreshToken(obj[key]);
                    } else delete obj[key];
                }
            });
        };

        retainRefreshToken(data);
        localStorage.setItem(auth0CacheKey(), JSON.stringify(data));
    }

    // Cleans up user tokens
    localStorage.removeItem(auth0UserKey());
};

window.addEventListener('beforeunload', cleanUpAuth0);
