<template>
  <div id="app-container" class="light tenant">
    <div class="mobile-top" v-if="appNavigation && $vssWidth < 1180">
      <MobileTopNavigationComponent
          @search="updateSearch"
          class="component-content"
      />
    </div>
    <v-container
        justify="start"
        class="no-padding-margin fill-height flex-direction-col"
    >
      <v-row
          no-gutters
          justify="start"
          align="start"
          class="flex-grow-1 full-width"
      >
        <v-col
            v-if="appNavigation && $vssWidth >= 1180"
            no-gutters
            class="nav-container desktop fill-height"
        >
          <DesktopNavigationComponent
              @search="updateSearch"
              class="component-content"
          />
        </v-col>
        <v-col no-gutters class="fill-height">
          <div class=" app-content">
            <transition name="route-change">
              <router-view
                  class="component-content route-content"
                  :key="$route.path"
                  :setupAppTheme="setupAppTheme"
              />
            </transition>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <div
        class="nav-container mobile-bottom"
        v-if="appNavigation && $vssWidth < 1180"
    >
      <MobileBottomNavigationComponent class="component-content"/>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import {useTheme} from 'vuetify';
import MobileTopNavigationComponent from "./components/nav/MobileTopNavigationComponent.vue";
import MobileBottomNavigationComponent from "./components/nav/MobileBottomNavigationComponent.vue";
import DesktopNavigationComponent from "./components/nav/DesktopNavigationComponent.vue";
import routerConfig from "./router/config.json";
import {get} from "@/utils/localStorage.js";

export default {
  components: {
    MobileTopNavigationComponent,
    MobileBottomNavigationComponent,
    DesktopNavigationComponent,
  },
  data() {
    return {
      appRoutes: routerConfig.app,
      model: {
        defaultTheme: "light",
        vuetifyTheme: useTheme()
      },
      content: {
        search: ""
      }
    };
  },
  methods: {
    /**
     * Sets up router content height.
     */
    setupApp() {
      try {
        const root = document.documentElement;
        const mobileTopNav = document.querySelector(".mobile-top");
        const mobileBottomNav = document.querySelector(".mobile-bottom");
        let height = 0;
        let margin = 0;

        if (mobileTopNav) height += mobileTopNav.offsetHeight;
        if (mobileBottomNav) margin += mobileBottomNav.offsetHeight;

        root.style.setProperty("--app-content-height-subtract", `${height}px`);
        root.style.setProperty("--app-content-height-margin", `${margin}px`);
      } catch (error) {
      }
    },
    /**
     * Sets up app styles.
     * Converts website to default scrollable page if outside of app
     */
    setupAppStyles(noApp = false) {
      const styleId = "noApp";

      if (noApp) {  // Create new style element outside of app
        if (!document.getElementById(styleId)) {
          const style = document.createElement('style');
          style.id = styleId;
          document.head.appendChild(style);
          style.sheet.insertRule('#app { position: absolute; }', style.sheet.cssRules.length);
          style.sheet.insertRule('#app-container { height: 100%; overflow-y: auto; }', style.sheet.cssRules.length);
          style.sheet.insertRule('.app-content { height: 100%; }', style.sheet.cssRules.length);
        }
      } else {  // Removes style element outside of app
        let existingStyle = document.getElementById("noApp");
        if (existingStyle) existingStyle.remove();
      }
    },
    /**
     * Sets up app theme.
     */
    setupAppTheme(newTheme = this.model.defaultTheme, ignoreRoute = false) {
      const appContainer = document.getElementById("app-container");
      if (appContainer && newTheme && (this.appNavigation || ignoreRoute)) {
        appContainer.classList.remove("light", "dark");
        appContainer.classList.add(newTheme);
        this.model.vuetifyTheme.global.name = newTheme;
      }
    },
    /**
     * Updates app wide search term.
     */
    updateSearch(e = "") {
      this.content.search = e;
    },
    /**
     * Updates document title based on the route, appending a formatted route string to the base title.
     * @param {string} route - Current route path.
     */
    updateDocumentTitle(route) {
      const baseTitle = process.env.VUE_APP_TENANT_NAME;
      const routeToTitle = (route) => {
        const parts = route.slice(1).split('/');
        const capitalizedParts = parts.map(part =>
            part.replace(/_/g, ' ').replace(/-/g, ' ')
                .split(' ')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ')
        );
        return capitalizedParts.join(' - ');
      };
      if (route !== '/') document.title = `${baseTitle} - ${routeToTitle(route)}`;
      else document.title = baseTitle;
    }
  },
  mounted() {
    setTimeout(() => {
      this.setupApp();
      window.addEventListener("resize", () => {
        this.setupApp();
      });
    }, 500);
  },
  computed: {
    appNavigation() {
      return this.appRoutes.includes(this.$route.path.toLowerCase())
    },
  },
  watch: {
    '$route': {
      immediate: true,
      handler(to) {
        this.updateDocumentTitle(to.path);
        if (!this.appRoutes.includes(to.path.toLowerCase())) {
          this.setupAppStyles(true);
          this.setupAppTheme("light", true);
        } else {
          this.setupAppStyles();
          this.setupAppTheme(get('theme'));
        }
      }
    },
  }
};
</script>
<style lang="scss">
@import "style/appStyling.scss";
@import "style/pageTemplate.scss";
@import "style/tenant/tenantStyling";
@import "tenant/tenantEncouragingAcademy.scss";

#app {
  position: fixed;
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#app > .light {
  color: var(--p-primary-light);
  background-color: var(--background-primary-light);
}

#app > .dark {
  color: var(--p-primary-dark);
  background-color: var(--background-primary-dark);
}

.component-content {
  position: relative;
}

.content-fullscreen {
  position: relative;
  height: 100vh;
  width: 100vw;
}

#app-container {
  display: block;
  height: 100vh;
  overflow-y: hidden;
}

.app-content {
  position: relative;
  height: calc(100vh - var(--app-content-height-subtract));
  overflow-y: scroll;
}
.route-content > .component-container, .route-content.component-container {
  margin-bottom: var(--app-content-height-margin) !important;
}

.light .nav-content {
  background-color: var(--background-secondary-light);
}

.dark .nav-content {
  background-color: var(--background-secondary-dark);
}

.nav-content {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.component-container {
  padding: calc(.16rem + .16vw) !important;

  &--double-padding {
    padding: calc(.32rem + .32vw) !important;
  }
}

.route-change-leave-active {
  transition: all .2s ease-out;
}
.route-change-enter-active {
  transition: all .4s ease-out;
}

.route-change-enter-from {
  opacity: 0;
  transform: translateX(-5px);
}

.route-change-enter-to {
  opacity: 1;
}

.route-change-leave-from {
  opacity: 1;
  transform: translateX(0px);
}

.route-change-leave-to {
  opacity: 0;
  transform: translateX(5px);
}
</style>
