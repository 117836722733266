<script setup>
/* eslint-disable */
import {PersonIcon} from "@radix-icons/vue";
import {useAuth0} from "@auth0/auth0-vue";
import {computed} from "vue";

const {user} = useAuth0();
const isUserLoaded = computed(() => !!user.value.email);
</script>

<template>
  <v-container v-if="isUserLoaded" class="no-padding-margin">
    <v-row justify="start" align="center">
      <v-col no-gutters class="flex-grow-0 flex-shrink-0">
        <div
            :class="`nav-user-image-content ${(!user.picture || pictureError) ? 'nav-user-image-content-border' : ''}`">
          <PersonIcon
              v-if="!user.picture || pictureError"
              stroke-width="1"
              class=" user-image"
              style="width: 100%; height: 100%;"
          />
          <v-img
              v-else
              :src="user.picture"
              class="user-image"
              style="width: 100%"
              @error="pictureError = true"
          />
        </div>
      </v-col>
      <v-col no-gutters class="nav-user-name flex-grow-0 flex-shrink-0">
        <h3 class="">{{ $t('nav.hello') }}, {{ user.given_name.substr(0, 9) + ((user.given_name.length > 9) ? '..' : '') }}</h3 class="">
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      pictureError: false,
    };
  },
};
</script>
<style lang="scss">
.nav-user-image-content {
  width: calc(2rem + 0.65vw + 0.65vh);
  height: calc(2rem + 0.65vw + 0.65vh);
  border-radius: 100%;
  overflow: hidden;
}

.nav-user-image {
  margin: auto;
}
.nav-user-name {
  padding-left: 0 !important;
}

.light .nav-user-image-content-border {
  border: var(--p-primary-light) solid 3px;
}

.dark .nav-user-image-content-border {
  border: var(--p-primary-dark) solid 3px;
}
</style>
